import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

class GateTypes extends React.Component {
	render() {
		const siteTitle =
			'Gate Types - J&J Automatic Service & Design'
		const siteDescription =
			'J&J Gates offers Many Custom Gate Types in the Arlington and Dallas-Fort Worth, TX Area.  Give Us a Call to Learn More About Our Gates!'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title="Gate Types - J&J Automatic Service & Design"
					description={siteDescription}
				/>
				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Gate Types</h1>
						<hr />
					</div>
				</div>
				<div className="container gate-types my-5">
					<div className="set-width text-center mx-auto">
						<p className="mx-auto">
							You can choose from many gate types. In fact, J&J Gates Service
							and Design custom install any type of gate you can imagine. Want
							to look strong and superior? How about looking exclusive? Or maybe
							you need a simple gate to protect your home. Whatever it is that
							you want or need, we can install all gate types. Maybe you need
							just a small gate to swing open and allow the right people access.
							<strong>
								<em>
									Just contact us at for a wide selection of quality gates in
									Dallas and Fort Worth, TX
								</em>
							</strong>
							.
						</p>
					</div>
				</div>
				<div className="container gate-types">
					<div className="set-width text-center mx-auto">
						<h4 className="mt-0">Materials and Uses</h4>
						<hr className="mb-3" />
						<p>
							At J & J Gates Service and Design, we offer nearly endless options
							when it comes to gate design. We work with both prefabricated and
							custom-designed gates to meet your needs. Whether you are on a
							budget, focused on security, or want the most beautiful gate in
							the neighborhood, we can help you achieve your goals. We are happy
							to help you determine the right materials, size, and location of
							your gate to compliment your property. Give us a call at to learn
							more about the types of gates we offer, including:
						</p>
					</div>

					<div className="container d-padding-b">
						<div className="row gate-types2 text-center mt-5 ">
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/aluminum">
									<div className="gate-svc-cont">
										<h5>Aluminum Gates</h5>
										<p>
											<em>
												Aluminum gates have gained a ton of popularity in the
												commercial market for some time now. Why do businesses
												love them so much?
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/chain-link-gates">
									<div className="gate-svc-cont">
										<h5>Chain Link Gates</h5>
										<p>
											<em>
												Affordable and long-lasting, chain link gates are ideal
												for property owners focused on security. We install and
												repair chain link gates for homes and businesses.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/driveway-gates">
									<div className="gate-svc-cont">
										<h5>Driveway Gates</h5>
										<p>
											<em>
												Whether you want stunning or simple, driveway gates add
												value to your property as well as security. We offer
												many kinds of driveway gates in a variety of materials.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/estate-gates">
									<div className="gate-svc-cont">
										<h5>Estate Gates</h5>
										<p>
											<em>
												From prefabricated to custom designs, estate gates can
												provide excellent security and privacy to your property.
												Talk to us about our wide selection today!
											</em>
										</p>
									</div>
								</Link>
							</div>
						</div>

						<div className="row gate-types2 text-center mt-5">
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/pedestrian-gates">
									<div className="gate-svc-cont">
										<h5>Pedestrian Gates</h5>
										<p>
											<em>
												When you need a pedestrian gate installed in your
												courtyard, garden, or other areas, let our crew help you
												find the perfect solution. These walk-through gates
												provide convenience and security.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/security-gates">
									<div className="gate-svc-cont">
										<h5>Security Gates</h5>
										<p>
											<em>
												Used in both commercial and some residential settings,
												security gates offer the best in protection and peace of
												mind. You can count on our technicians to install your
												security gate with care and precision.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/wooden-gates/">
									<div className="gate-svc-cont">
										<h5>Wooden Gates</h5>
										<p>
											<em>
												From private residences to apartment complexes, we have
												residential gate services to fulfill every need.
											</em>
										</p>
									</div>
								</Link>
							</div>

							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/wrought-iron-gates/">
									<div className="gate-svc-cont">
										<h5>Wrought Iron Gates</h5>
										<p>
											<em>
												Nothing says quality like a beautiful wrought iron gate
												at your entryway. J & J Gates Service and Design are
												proud to offer custom wrought iron gates in Dallas and
												Fort Worth, TX.
											</em>
										</p>
									</div>
								</Link>
							</div>
						</div>
						<div className="set-width text-center mx-auto mt-5">
							<h4 className="mt-0">Types of Operation</h4>
							<hr className="mb-3" />
							<p>
								Do you want your gate to make a statement? Or do you need a
								basic gate system? No matter what you are looking for, our
								installers have an operation system perfect for you. We
								specialize in all types of gate operation and access systems so
								you can enjoy convenience and security. Our experts can also
								help you make the best decision based on your available space.
								Sliding gates, for example, require more space than swing gates.
								Contact us if you have questions about the types of gates we
								install:
							</p>
						</div>
						<div className="row gate-types2 text-center mt-5 ">
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/automatic-gates">
									<div className="gate-svc-cont">
										<h5>Automatic Gates</h5>
										<p>
											<em>
												Enjoy with convenient security that comes with an
												automatic gate system from J & J gates Service and
												Design. Available in a variety of materials and
												operating systems.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/electric-gates">
									<div className="gate-svc-cont">
										<h5>Electric Gates</h5>
										<p>
											<em>
												Choose J & J Gates Service and Design for electric gate
												installation in Dallas and Fort Worth, TX. We will
												ensure that your electric gate is working properly!
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/sliding-gates/">
									<div className="gate-svc-cont">
										<h5>Sliding Gates</h5>
										<p>
											<em>
												If you have plenty of space, a sliding gate is a
												reliable option for both residential and commercial
												properties. Choose from a variety of high-quality
												materials and operating systems.
											</em>
										</p>
									</div>
								</Link>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<Link to="/gates/gate-types/solar-gates/">
									<div className="gate-svc-cont">
										<h5>Solar Gates</h5>
										<p>
											<em>
												When you have plenty of sunlight and want to reduce
												electricity use, solar gates provide an excellent
												solution. These gate systems are popular where no tall
												buildings or trees are obstructing the sun.
											</em>
										</p>
									</div>
								</Link>
							</div>
						</div>
						<div className="row gate-types2 text-center mt-5 w-25 mx-auto ">
							<div className="col-xl-12 col-lg-12">
								<Link to="/gates/gate-types/swing-gates/">
									<div className="gate-svc-cont">
										<h5>Swing Gates</h5>
										<p>
											<em>
												Elegant and functional, swing gates provide an extra
												level of class to any home. Choose from single swing
												gates or double swing gates as well as beautiful
												materials and access systems.
											</em>
										</p>
									</div>
								</Link>
							</div>
						</div>
						<div className="row gate-types2 mt-5">
							<div className="col-xl-12">
								<p>
									No matter what type of gate you need, make J & J Gates Service
									and Design your first choice.{' '}
									<b>
										<em>
											Call us at for the best selection of commercial and
											residential gate types in Dallas-Fort Worth, TX.
										</em>
									</b>
								</p>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default GateTypes

export const GateTypesQuery = graphql`
	query GateTypesQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
